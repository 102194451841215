<template>
  <div class="flex flex-col items-center bg-gray-100 w-full pt-10 px-3 md:px-0">
    <div class="flex flex-col bg-white w-full max-w-sm">
      <div class="py-5 flex items-center justify-center">
        <app-image
          v-if="getBusinessLogo()"
          :src="getBusinessLogo()"
          class="flex-none w-16 bg-white"
        />
        <app-image
          v-else-if="getBusinessIcon()"
          :src="getBusinessIcon()"
          class="flex-none w-16 bg-white"
        />
        <div v-else>
          <app-text-icon
            :text="business?.name ?? null"
            icon_outline="BriefcaseIcon"
          />
        </div>
      </div>
      <div class="border-t w-full mb-2"></div>
      <div class="flex flex-row px-4 py-4 justify-between">
        <div class="flex flex-col space-y-2">
          <p class="text-sm">
            {{
              $t("payment.link.new_payment_request", {
                business_name: business?.name,
              })
            }}
          </p>
          <p class="text-4xl font-semibold">
            {{
              $formats.currency(
                payment_link.currency,
                payment_link.amount ?? 0.0
              )
            }}
          </p>

          <div class="flex flex-col">
            <div class="flex flex-row space-x-2 items-center">
              <p class="text-sm text-gray-400 w-8">
                {{ $t("general.to") }}
              </p>
              <p class="text-sm">{{ payment_link.email }}</p>
            </div>
            <div class="flex flex-row space-x-2 items-center ml-10">
              <p class="text-sm">{{ payment_link.phone_no }}</p>
            </div>
          </div>
        </div>
        <app-receipt />
      </div>
      <div class="flex flex-col px-4 break-all">
        <p class="text-sm font-medium">{{ payment_link.title }}</p>
        <p class="text-sm text-gray-500">{{ payment_link.description }}</p>
        <div class="mt-2 flex flex-row space-x-2 items-center">
          <p class="text-sm text-gray-400 w-28">
            {{ $t("payment.link.ref1") }}
          </p>
          <p class="text-sm">{{ payment_link.reference }}</p>
        </div>
        <div class="flex flex-row space-x-2 items-center">
          <p class="text-sm text-gray-400 w-28">
            {{ $t("payment.link.ref2") }}
          </p>
          <p class="text-sm">{{ payment_link.reference_2 }}</p>
        </div>

        <app-button
          type="submit"
          :loading="loading"
          class="w-full mt-4 mb-4"
          :showProceedIcon="true"
          >{{ $t("payment.email.open_payment_page") }}
        </app-button>
      </div>

      <div class="mt-2 mb-2 border-t flex flex-col text-sm px-4 space-y-2">
        <p class="flex mt-4">
          {{ $t("payment.email.payment_link_id") }} &lt;{{
            $t("general.generated_id")
          }}&gt;
        </p>
        <div class="flex-inline">
          {{ $t("payment.email.question") }}
          <span class="text-bluelink">{{
            business != null ? business.support_detail.support_email : ""
          }}</span>
          {{ $t("payment.email.question_2") }}
          <span class="text-bluelink">{{
            business != null ? business?.support_detail?.support_tel_no : ""
          }}</span>
        </div>
        <div class="flex flex-col">
          <p>{{ $t("payment.email.regards") }}</p>
          <p>{{ business != null ? business.name : "" }}</p>
        </div>
        <p>{{ $t("payment.email.having_trouble") }}</p>
        <p class="text-center bg-blue-200 text-bluelink">
          {{ payment_link_url }}
        </p>
      </div>
      <div class="mt-2 mb-4 border-t flex flex-col text-sm px-4 space-y-2">
        <p class="mt-2 text-center">{{ $t("payment.email.thank_you") }}</p>
      </div>
    </div>
    <div class="justify-center m-10">
      <app-logo-powered-by-payje-dark/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    payment_link() {
      return this.$store.getters["paymentStore/payment_link"];
    },

    business() {
      return this.masterBusinessMode
        ? this.payment_link.business
        : this.$store.getters["businessStore/business"];
    },

    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/payment/link/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business?.media?.find(
        (media) => media.collection_name == "icon"
      )?.original_url;
    },
  },
};
</script>
