<template>
   <!-- Modal Amend Business-->
  <app-modal
    v-if="business.business_status_id == BUSINESS_STATUS.AMENDED"
    :show="showModalActive"
    @close="cancelButton"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.amend_business_details")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("payment.info.activate_account_amend_business") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

   <!-- Modal Incomplete Business Details-->
   <app-modal
   v-if="business.business_status_id == BUSINESS_STATUS.CREATED"
   :show="showModalActive"
   @close="cancelButton"
   :textButtonCancel="$t('payment.info.active_later')"
 >
   <template v-slot:title>{{
     $t("payment.info.incomplete_details")
   }}</template>
   <template v-slot:body>
     <p class="text-sm text-gray-500">
       {{ $t("payment.info.activate_account_incomplete_details") }}
     </p>
   </template>
   <template v-slot:action>
     <app-button
       @onClick="$router.push({ name: 'business' })"
       @click="showModalActive = false"
       :showf70Icon="false"
       :primary="false"
       class="
         sm:ml-3 sm:w-auto
         bg-primary
         hover:bg-primary-100
         text-white
         outline-none
       "
     >
       {{ $t("payment.info.active_account") }}
     </app-button>
   </template>
 </app-modal>

  <!-- Modal Business Under Review-->
  <app-modal
  v-if="business.business_status_id == BUSINESS_STATUS.SUBMITTED"
  :show="showModalActive"
  :showCancelButton="false"
  @close="cancelButton"
  :textButtonCancel="$t('payment.info.okay')"
>
  <template v-slot:title>{{
    $t("payment.info.account_under_review")
  }}</template>
  <template v-slot:body>
    <p class="text-sm text-gray-500">
      {{ $t("payment.info.activate_account_under_review") }}
    </p>
  </template>
  <template v-slot:action>
    <app-button
    @onClick="cancelButton"
      :showf70Icon="false"
      :primary="false"
      class="
        sm:ml-3 sm:w-auto
        bg-primary
        hover:bg-primary-100
        text-white
        outline-none
      "
    >
      {{ $t("payment.info.okay") }}
    </app-button>
  </template>
</app-modal>

  <!-- Modal Rejected Business-->
  <app-modal
  v-if="business.business_status_id == BUSINESS_STATUS.REJECTED"
  :show="showModalActive"
  :showCancelButton="false"
  @close="cancelButton"
  :textButtonCancel="$t('payment.info.okay')"
>
  <template v-slot:title>{{
    $t("payment.info.account_rejected")
  }}</template>
  <template v-slot:body>
    <p class="text-sm text-gray-500">
      {{ $t("payment.info.activate_account_rejected") }}
    </p>
  </template>
  <template v-slot:action>
    <app-button
    @onClick="cancelButton"
      :showf70Icon="false"
      :primary="false"
      class="
        sm:ml-3 sm:w-auto
        bg-primary
        hover:bg-primary-100
        text-white
        outline-none
      "
    >
      {{ $t("payment.info.okay") }}
    </app-button>
  </template>
</app-modal>

  <transition>
    <div class="flex flex-col md:flex-row w-full">
      <div
        :class="[
          'flex flex-col w-full py-2 md:py-10 px-4 md:px-10',
          showPreview ? 'md:w-2/5' : 'md:w-4/5',
        ]"
      >
        <p class="mb-5 font-semibold text-xl">
          {{ $t("payment.create.title") }}
        </p>
        <form
          class="flex flex-col space-y-4 items-start"
          @submit.prevent="createPaymentLink"
        >
          <app-form-select-master-business-list-business
            class="w-full"
            v-model="payment_link.business"
            :loading="loading"
            :errorMessage="errors.business_id"
          />

          <app-form-input
            class="w-full"
            type="email"
            :labelText="$t('payment.create.email_label')"
            :placeholder="$t('payment.create.email_placeholder')"
            v-model="payment_link.email"
            :errorMessage="errors.email"
          />

          <app-form-telephone-no
            class="w-full"
            :labelText="$t('payment.create.phone_no_label')"
            v-model="payment_link.phone_no"
            :errorMessage="errors.phone_no"
          />

          <app-form-input
            class="w-full break-words"
            type="text"
            :labelText="$t('payment.create.title_label')"
            :placeholder="$t('payment.create.title_placeholder')"
            v-model="payment_link.title"
            :errorMessage="errors.title"
          />

          <app-form-input-with-select
            class="w-full"
            type="number"
            step=".01"
            :labelText="$t('payment.create.amount_label')"
            :placeholder="$t('payment.create.amount_placeholder')"
            v-model="payment_link.amount"
            v-model:option="payment_link.currency"
            :errorMessage="errors.amount"
            @input="validateAmount"
            maxLength="7"
            :options="[
              {
                id: 1,
                desc: 'MYR',
              },
            ]"
          />

          <app-form-textarea
            class="w-full"
            :labelText="$t('payment.create.desc_label')"
            :placeholder="$t('payment.create.desc_placeholder')"
            v-model="payment_link.description"
            :errorMessage="errors.description"
          />

          <app-form-input
            class="w-full"
            type="text"
            :labelText="$t('payment.create.ref1_label')"
            :placeholder="$t('payment.create.ref1_placeholder')"
            v-model="payment_link.reference"
            :errorMessage="errors.reference"
          />

          <app-form-input
            class="w-full"
            type="text"
            :labelText="$t('payment.create.ref2_label')"
            :placeholder="$t('payment.create.ref2_placeholder')"
            v-model="payment_link.reference_2"
            :errorMessage="errors.reference_2"
          />

          <app-form-input
            class="w-full"
            type="url"
            :labelText="$t('payment.create.redirect_url_label')"
            :placeholder="$t('payment.create.redirect_url_placeholder')"
            v-model="payment_link.redirect_url"
            :errorMessage="errors.redirect_url"
          />

          <app-form-checkbox
            v-model="payment_link.send_email"
            :errorMessage="errors.send_email"
          >
            {{ $t("payment.create.send_email") }}
          </app-form-checkbox>

          <div />

          <app-button
            type="submit"
            :loading="loading"
            class="w-full"
            :showProceedIcon="true"
            :disabled="hasActiveBusiness"
            >{{ $t("payment.create.title") }}
          </app-button>

          <p v-if="hasActiveBusiness" class="text-gray-500 text-sm">
            {{ $t("payment.info.active_your_account") }}
          </p>
        </form>
      </div>
      <div
        :class="[
          'md:overflow-y-auto md:border-l py-4 md:py-8 px-4 md:px-10 flex flex-col',
          showPreview ? 'md:w-3/5' : 'md:w-1/5',
        ]"
      >
        <div
          :class="[
            'flex flex-col md:flex-row items-center md:space-x-4',
            showPreview ? '' : 'justify-center',
          ]"
        >
          <p
            class="
              w-full
              text-center
              md:text-left md:w-auto
              text-xl
              font-semibold
              mb-2
              md:mb-0
            "
            v-if="showPreview"
          >
            {{ $t("payment.create.preview_title") }}
          </p>
          <app-button
            :primary="false"
            :showf70Icon="false"
            width="w-full md:w-auto"
            :class="[togglePreview ? 'mb-0' : 'mb-4']"
            @click="togglePreview"
          >
            {{
              showPreview
                ? $t("payment.create.preview_hide")
                : $t("payment.create.preview_show")
            }}
          </app-button>
        </div>

        <div
          class="flex flex-row mt-4 justify-center md:justify-start"
          v-if="showPreview"
        >
          <app-button-menu
            class="
              flex-none
              w-auto
              space-x-3
              flex
              items-center
              font-medium
              border-b-2
              hover:border-primary
              h-auto
            "
            :rounded="false"
            :active="tabSelectedNo == 1"
            @onMenuClick="onTabClick(1)"
          >
            {{ $t("payment.create.preview_tab_payment_page") }}
          </app-button-menu>

          <app-button-menu
            class="
              flex-none
              w-auto
              space-x-3
              flex
              items-center
              font-medium
              border-b-2
              hover:border-primary
              h-auto
            "
            :rounded="false"
            :active="tabSelectedNo == 2"
            @onMenuClick="onTabClick(2)"
          >
            {{ $t("payment.create.preview_tab_email_page") }}
          </app-button-menu>

          <div class="border-b-2 w-0 md:w-auto flex-none md:flex-grow">
            &nbsp;
          </div>
        </div>

        <div
          class="mt-4 md:mt-8 flex flex-col"
          v-if="showPreview && tabSelectedNo == 1"
        >
          <div
            class="
              bg-gray-200
              rounded-t-md
              flex flex-row
              px-2
              py-2
              items-center
              space-x-1
              md:space-x-3
              pr-3
            "
          >
            <div class="flex flex-row space-x-1">
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
            </div>
            <div
              class="
                flex flex-row
                bg-gray-100
                md:w-full
                rounded-md
                px-2
                py-1
                items-center
              "
            >
              <LockClosedIcon class="w-3 h-3 mr-1" />
              <p class="text-xs">{{ payment_link_url }}</p>
            </div>
          </div>

          <LinkPage
            class="rounded-b-md"
            :previewMode="true"
            :masterBusinessMode="this.isMasterBusiness"
          />
        </div>

        <div
          v-if="showPreview && tabSelectedNo == 2"
          class="mt-4 md:mt-8 flex flex-col"
        >
          <EmailPage
            :previewMode="true"
            :masterBusinessMode="this.isMasterBusiness"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
import EmailPage from "@/views/pages/main/payment/payment-link/email-page";
import LinkPage from "@/views/pages/main/payment/payment-link/payment-page";
import Role from "@/utils/const/role";
import { LockClosedIcon } from "@heroicons/vue/solid";

export default {
  components: {
    LockClosedIcon,
    LinkPage,
    EmailPage,
  },

  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      Role: Role,
      showModalActive: true,
      showPreview: true,
      tabSelectedNo: 1,
      maxAmount: 30000,
      minAmount: 5,
      checkRoleMasterBusiness: [
        Role.MASTER_BUSINESS_OWNER,
        Role.MASTER_BUSINESS_ADMIN,
        Role.MASTER_BUSINESS_MANAGER,
        Role.MASTER_BUSINESS_DEVELOP,
        Role.MASTER_BUSINESS_SUPPORT,
      ],
    };
  },

  beforeCreate() {
    this.$store.commit("paymentStore/initPaymentLink");
  },

  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },

    loading() {
      return this.$store.getters["paymentStore/loading"];
    },

    payment_link() {
      return this.$store.getters["paymentStore/payment_link"];
    },

    list_role() {
      return this.$store.getters["masterBusinessStore/list_role"];
    },

    errors() {
      return this.$store.getters["paymentStore/errors"] ?? this.emptyErrors;
    },

    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/payment/link/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }

      return this.business.business_status_id != BUSINESS_STATUS.APPROVED;
    },

    isMasterBusiness() {
      return this.checkRoleMasterBusiness.includes(this.selectedRole?.role_id);
    },
  },

  watch: {
    "payment_link.business"(value) {
      this.payment_link.business_id = value.id;
    },
  },

  methods: {
    validateAmount(event) {
      const input = event.target;
      const amount = parseFloat(event.target.value.trim());

      if (isNaN(amount)) {
        // if input is not a number
        input.value = ""; // clear input
        return false;
      }

      if (amount > this.maxAmount) {
        this.errors.amount = [
          this.$t("payment.create.max_amount", {
            max_amount: this.$formats.currency(
              this.payment_link?.currency,
              this.maxAmount
            ),
          }),
        ];
        return;
      }

      if (amount < this.minAmount) {
        this.errors.amount = [
          this.$t("payment.create.min_amount", {
            min_amount: this.$formats.currency(
              this.payment_link?.currency,
              this.minAmount
            ),
          }),
        ];
        return;
      }

      this.errors.amount = [];
    },

    togglePreview() {
      this.showPreview = !this.showPreview;
    },

    onTabClick(tabNo) {
      this.tabSelectedNo = tabNo;
    },

    async createPaymentLink() {
      this.resetState();

      const paymentLink = await this.$store.dispatch(
        "paymentStore/createOrUpdatePaymentLink"
      );

      if (paymentLink == null) return;

      this.$router.push({ path: "/payment/link" });
    },

    cancelButton() {
      this.$router.push({ path: "/payment/link/index" });
    },

    resetState() {
      this.$store.commit("paymentStore/setErrorMessage");
    },
  },
};
</script>
